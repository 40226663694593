<script setup lang="ts">
import type { ArticleFeature } from "@/types";
import hexRgb from "hex-rgb";

type FeatureItemProps = {
  condensed?: boolean;
  id: string;
};

const props = defineProps<FeatureItemProps>();

const feature = ref<ArticleFeature>();
const { getCdnUrl } = useEnvPaths();

const { isWebpSupported } = useWebpSupport();

const url = `${getCdnUrl()}/json/features/${props.id}`;
const { data, error } = await useFetch(url, { server: true });

onMounted(() => {
  feature.value = data.value as ArticleFeature;
});

const backgroundColor = computed(() => {
  if (feature.value) {
    const colorRgb = hexRgb(feature.value.properties!.colors!.background!);
    return `rgba(${colorRgb.red}, ${colorRgb.green}, ${colorRgb.blue}, .7)`;
  }
  return "";
});

const getBackgroundImage = computed(() => {
  let path = "none";
  if (feature.value!.properties) {
    path = feature.value!.properties.background
      ? `url(${feature.value!.properties.background}.${isWebpSupported.value ? "webp" : "jpg"})`
      : "none";
  }

  return path;
});
</script>

<template>
  <div
    v-if="feature"
    ref="featureItem"
    role="button"
    tabIndex="”0”"
    :aria-label="`Read more: ${feature.title}`"
    class="feature-item"
    :class="{ condensed: props.condensed }"
  >
    <div class="feature-item--title">
      <NuxtLink
        :to="
          feature.url && feature.newWindow
            ? feature.url
            : `/article/${feature.id}`
        "
        :target="feature.url && feature.newWindow ? '_blank' : '_self'"
      >
        <h3
          :style="{ color: feature.properties!.colors!.title || 'white' }"
          v-html="feature.title"
        />

        <p v-if="feature.content !== ''" v-html="feature.content" />
      </NuxtLink>
    </div>

    <div class="feature-item--content">
      <NuxtLink
        :to="
          feature.url && feature.newWindow
            ? feature.url
            : `/article/${feature.id}`
        "
        :target="feature.url && feature.newWindow ? '_blank' : '_self'"
      >
        <div
          v-if="feature.properties!.background !== 'none'"
          class="background-image"
          :style="[
            feature.properties!.background !== 'none'
              ? { backgroundImage: getBackgroundImage }
              : '',
          ]"
        />
      </NuxtLink>
    </div>

    <div class="cta">
      <NuxtLink
        :to="
          feature.url && feature.newWindow
            ? feature.url
            : `/article/${feature.id}`
        "
        :target="feature.url && feature.newWindow ? '_blank' : '_self'"
        class="secondary-button full white-glow"
      >
        {{ feature.cta }}
        <span v-if="feature.newWindow" class="external-link">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 20 20"
          >
            <path
              id="icon-external-link"
              d="M4.722,2.5A2.239,2.239,0,0,0,2.5,4.722V20.278A2.239,2.239,0,0,0,4.722,22.5H20.278A2.239,2.239,0,0,0,22.5,20.278V12.5H20.278v7.778H4.722V4.722H12.5V2.5Zm10,0V4.722h3.984L8.381,15.048l1.571,1.571L20.278,6.293v3.984H22.5V2.5Z"
              transform="translate(-2.5 -2.5)"
              fill="#fff"
            />
          </svg>
        </span>
      </NuxtLink>
    </div>
  </div>

  <template v-if="error">
    <LazyErrorMessage :error="error" />
  </template>
</template>

<style lang="scss">
@import "../assets/scss/_vars";

.feature-item {
  scroll-snap-align: start;

  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr auto;
  gap: 2rem;

  background-color: black;
  border-radius: 30px;
  transition: all 0.25s;
  user-select: none;
  // cursor: pointer;

  &:hover,
  &:focus {
    background-color: v-bind("backgroundColor");
    outline: none;

    .cta {
      .secondary-button {
        background: transparent;

        &:hover {
          background: black;
        }
      }
    }
  }

  &.condensed {
    min-height: 100px;
  }

  .feature-item--title {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    a {
      text-decoration: none;
    }

    h3 {
      font: normal normal bold 3.5rem/1.15 Poppins;
      color: white;
      padding: 0;
      margin: 0;
    }

    p {
      font-size: 2.1rem;
      padding: 0;
      margin: 0.5rem 0;
    }

    @media (max-width: $device-xl) {
      h3 {
        font-size: 3rem;
      }

      p {
        font-size: 1.8rem;
      }
    }

    @media (max-width: $device-xs) {
      h3 {
        font-size: 3.5rem;
      }

      p {
        font-size: 2rem;
      }
    }
  }

  .feature-item--content {
    border-radius: 30px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .background-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      transition: all 0.25s;
    }

    @media (max-width: $media-query-mobile) {
      border-radius: 15px;
    }
  }

  &:hover,
  &:focus {
    .feature-item--content {
      .background-image {
        transform: scale(1.1);
      }
    }
  }

  .cta {
    padding-top: 2rem;

    .external-link {
      margin-left: 10px;
    }

    .feature-item-link {
      display: block;
      border: white solid 2px;
      border-radius: 4rem;
      padding: 1.5rem 3rem;
      text-transform: uppercase;
      background: transparent;
      cursor: pointer;
      transition: all 0.25s;
      font-size: 1.6rem;
      font-weight: 700;
      text-align: center;
      color: white;
      text-decoration: none;
      background-color: rgba(black, 0.1);

      @media (max-width: $media-query-mobile) {
        padding: 1rem;
        font-size: 1.5rem;
      }

      &:hover {
        box-shadow: 0 0 5px 5px rgba(#fff, 0.5);
        background-color: rgba(black, 0.5);
      }
    }
  }
}
</style>
