export const useWebpSupport = () => {

	const isWebpSupported = ref(true);

	onMounted(() => {
		const elem = document.createElement("canvas");
		isWebpSupported.value = !!elem.getContext && !!elem.getContext("2d")
			? elem.toDataURL("image/webp").indexOf("data:image/webp") == 0
			: false;
	});

	return {
		isWebpSupported,
	}
}