<script setup lang="ts">
import { storeToRefs } from "pinia";
import SignUp from "../components/forms/SignUp.vue";

const useBlackLogo = useState("use-black-logo");
useBlackLogo.value = false;

const { isGdprRestricted } = storeToRefs(useGdprStore());

onMounted(() => {
  const { sendScreenView } = useAnalytics();
  sendScreenView({ action: "PageView", category: "index" }, true);
});
</script>

<template>
  <main class="container home-container">
    <HeroImageAnimations />
    <section class="hero-section">
      <div>
        <h2>At the intersection of art, technology, and entertainment.</h2>
        <h1>
          <span class="desktop-only"
            >Home of the NYE Ball<br />to Become a Modern-Day<br />Visitor
            Center</span
          >

          <span class="mobile-only"
            >Home of the NYE Ball<br />to Become a Modern-Day Visitor
            Center</span
          >
        </h1>
        <div class="ctas">
          <a
            href="/article/redevelopment-one-times-square"
            class="secondary-button glow"
            aria-label="Learn more about the Redevelopment One Times Square"
          >
            Learn More
          </a>
        </div>
      </div>
    </section>

    <section class="feature-slider-section">
      <FeatureSlider />
    </section>

    <section v-if="!isGdprRestricted" id="signupSection" class="signup-section">
      <SignUp />
    </section>

    <section>
      <Social />
    </section>
  </main>
</template>

<style lang="scss">
@import "../assets/scss/_vars";

.home-container {
  .hero-section {
    width: 100%;
    max-width: $max-view-width;
    margin: auto;
    color: white;
    padding: 15rem 2rem 6rem 3rem;
    user-select: none;

    h1,
    h2 {
      color: #ffffff;
      margin: 2rem 0 1rem 0;
    }

    h1 {
      text-shadow: 0px 10px 20px rgba(0, 0, 0, 40%);
      line-height: 1.1;
      @media screen and (max-width: $device-xs) {
        font-size: 6rem;
      }
    }

    @media screen and (max-width: $device-xs) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      margin-top: 53vh;
      background-color: white;

      h1,
      h2 {
        color: black;
      }

      h1 {
        font-size: 5rem;
        line-height: 5.2rem;
        text-shadow: none;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.2rem;
        font-weight: 700;
      }
    }

    .ctas {
      display: flex;
      margin-top: 5rem;
      margin-bottom: 1rem;
      justify-content: space-between;

      button {
        white-space: nowrap;

        &:first-of-type {
          margin-right: 2rem;

          img {
            max-height: 3rem;
          }
        }

        span {
          margin: 0 2.3rem 0 1.3rem;
        }

        img {
          max-height: 2.5rem;
        }
      }
    }
  }

  .feature-slider-section {
    width: 100%;
    margin: auto;
  }

  .signup-section {
    margin: 10rem auto 3rem auto;
    padding: 6rem 10rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    box-shadow: 0px 3px 44px #00000029;
    border-radius: var(--border-radius-lg);
    background-color: white;
    width: 100%;
    max-width: 1000px;

    &.is-active {
      transform: translateY(0);
      opacity: 1;
    }

    @media (max-width: $device-md) {
      width: 95%;
      padding: 2rem;
      border-radius: var(--border-radius-sm);
    }
  }
}
</style>
