<script setup lang="ts">
const { isMobile } = useDevice();

const showBackground = ref(0);

const heroContainer = ref<HTMLElement>();

let preloadedImages = 0;

const imageList = [
  {
    desktop: "/assets/hero/ots-wide-angle-desktop-a",
    mobile: "/assets/hero/ots-wide-angle-mobile-a",
  },
  {
    desktop: "/assets/hero/ots-wide-angle-desktop-b",
    mobile: "/assets/hero/ots-wide-angle-mobile-b",
  },
  {
    desktop: "/assets/hero/ots-top-desktop-a",
    mobile: "/assets/hero/ots-top-mobile-a",
  },
  {
    desktop: "/assets/hero/ots-top-desktop-b",
    mobile: "/assets/hero/ots-top-mobile-b",
  },
  {
    desktop: "/assets/hero/low-angle-eastside-desktop-a",
    mobile: "/assets/hero/low-angle-eastside-mobile-a",
  },
  {
    desktop: "/assets/hero/low-angle-eastside-desktop-b",
    mobile: "/assets/hero/low-angle-eastside-mobile-b",
  },
  {
    desktop: "/assets/hero/low-angle-westside-desktop-a",
    mobile: "/assets/hero/low-angle-westside-mobile-a",
  },
  {
    desktop: "/assets/hero/low-angle-westside-desktop-b",
    mobile: "/assets/hero/low-angle-westside-mobile-b",
  },
];

const heroHoldFixed = computed(() => {
  const isFixed = useState("hold-hero-fixed");
  if (heroContainer.value) {
    return {
      position: isFixed.value ? "fixed" : "absolute",
      top: isFixed.value ? `-${document.documentElement.scrollTop}px` : "0px",
    };
  }

  return {};
});

const { isWebpSupported } = useWebpSupport();
const { getCdnUrl } = useEnvPaths();

function getBackground(index: number): string {
  return `${getCdnUrl()}${isMobile ? imageList[index].mobile : imageList[index].desktop}.${isWebpSupported.value ? "webp" : "jpg"}`;
}

function onImageLoaded() {
  preloadedImages++;

  if (preloadedImages === imageList.length) {
    setInterval(() => {
      showBackground.value =
        showBackground.value < imageList.length - 1
          ? showBackground.value + 1
          : 0;
    }, 5000);
  }
}

onBeforeUnmount(() => {
  const isFixed = useState("hold-hero-fixed");
  isFixed.value = false;
});
</script>

<template>
  <div
    ref="heroContainer"
    class="hero-template-animations"
    style="width: 100vw; height: 100vh; z-index: -1; left: 0; top: 0"
    :style="heroHoldFixed"
  >
    <template v-for="(image, index) in imageList" :key="'image-' + index">
      <Transition name="fade">
        <div v-show="showBackground === index" class="background">
          <nuxt-img
            :src="getBackground(index)"
            alt=""
            width="100%"
            height="100%"
            @load="onImageLoaded"
          />
        </div>
      </Transition>
    </template>
    <div class="gradient-overlay" />
  </div>
</template>

<style lang="scss">
@import "../assets/scss/_vars";
.hero-template-animations {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #333;
  transition: position 1s;

  @media (max-width: $device-xs) {
    background-color: transparent;
  }

  .gradient-overlay {
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%, 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25) 60vh,
        rgba(84, 84, 84, 0) 100vh
      ),
      linear-gradient(rgba(255, 255, 255, 0) 60vh, rgba(255, 254, 245, 1) 100vh);

    @media (max-width: $device-xs) {
      display: none;
    }
  }

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }

    @media (max-width: $device-xs) {
      max-height: 53vh;
      overflow: hidden;
      // background-size: 110%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 2s ease-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-from {
  opacity: 0;
}
</style>
