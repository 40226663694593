<script setup lang="ts">
const { $gsap, $ScrollTrigger } = useNuxtApp();

const featureSlider = ref<HTMLElement | null>(null);
const featureList = ref<string[]>([]);

const nuxtApp = useNuxtApp();

const isFixed = useState("hold-hero-fixed");
isFixed.value = false;

const { getCdnUrl } = useEnvPaths();
const url = `${getCdnUrl()}/json/features/feature-list.json`;

const { data, error } = await useFetch(url, {
  key: "feature-list-data",
  method: "GET",
  getCachedData: (key) => {
    if (nuxtApp.isHydrating && nuxtApp.payload.data[key]) {
      return nuxtApp.payload.data[key];
    }

    // Check if the data is already cached in the static data
    if (nuxtApp.static.data[key]) {
      return nuxtApp.static.data[key];
    }

    return null;
  },
});
featureList.value = data.value as string[];

const isMobile = ref(false);

let featureTimeline: ReturnType<typeof $gsap.timeline> | null;
let scrollOffsetWidth = 0;

onMounted(async () => {
  const { isMobileDevice } = useUtils();
  isMobile.value = isMobileDevice();

  scrollOffsetWidth = featureSlider.value!.offsetWidth;

  if (!isMobile.value) {
    await nextTick();
    initScrollTrigger();
  }
});

function initScrollTrigger() {
  function getStartVals() {
    return window.innerHeight >= 1200 ? "top 20%" : "top 13%";
  }

  $ScrollTrigger.matchMedia({
    "(max-width: 2800px)": function () {
      featureTimeline = $gsap.timeline({
        scrollTrigger: {
          trigger: featureSlider.value,
          invalidateOnRefresh: true,
          pin: true,
          scrub: 1,
          start: getStartVals(),
          end: () => "+=" + scrollOffsetWidth,
          onUpdate: (self) => {
            if (self.progress >= 1 && self.direction > 0) {
              isFixed.value = false;
            }
          },
        },
      });
      featureTimeline.to(featureSlider.value, {
        x: () =>
          -(
            featureSlider.value!.scrollWidth -
            document.documentElement.clientWidth +
            100
          ) + "px",
        ease: "none",
        onStart: () => {
          isFixed.value = true;
        },
        onComplete: () => {
          isFixed.value = false;
        },
      });
    },
  });
}

onBeforeUnmount(() => {
  if (featureTimeline && featureTimeline.scrollTrigger) {
    featureTimeline.scrollTrigger.kill();
    featureTimeline.clear();
    featureTimeline.kill();
    featureTimeline = null;
  }
});
</script>

<template>
  <div class="feature-slider">
    <div
      ref="featureSlider"
      class="feature-slider--container"
      :class="{ 'is-mobile': isMobile }"
    >
      <template v-for="feature in featureList" :key="feature.id">
        <div v-if="Array.isArray(feature)" class="sub-articles">
          <FeatureItem
            v-for="nestedArticle in feature"
            :id="nestedArticle"
            :key="nestedArticle"
            condensed
          />
        </div>
        <FeatureItem v-else :id="feature" />
      </template>
    </div>
  </div>

  <template v-if="error">
    <LazyErrorMessage :error="error" />
  </template>
</template>

<style lang="scss">
@import "../assets/scss/_vars";
.feature-slider {
  margin: auto;
  .feature-slider--container {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    gap: 2rem;
    padding: 0 6rem;

    &.is-mobile {
      overflow-x: auto;
    }

    @media (max-width: 1366px) {
      padding: 0 5rem;
    }

    .feature-item {
      width: 550px;
      padding: 4rem;
      height: 85vh;
      max-height: 85vh;
      min-height: 640px;

      @media (max-width: $device-xl) {
        width: 480px;
      }

      @media (max-width: $device-md) {
        width: 550px;
      }

      @media (max-width: $device-xs) {
        width: 80vw;
        min-width: 80vw;
        min-height: 60vh;
        max-height: 70vh;
        padding: 2rem;
        gap: 1rem;
        border-radius: 15px;
      }

      @media (width: 1024px) and (height: 1366px) {
        height: 60vh;
        max-height: 60vh;
      }

      @media (min-height: 1200px) {
        max-height: 900px;
      }
    }

    .sub-articles {
      width: 550px;
      display: block;
      height: 85vh;
      max-height: 85vh;
      min-height: 640px;
      display: grid;
      grid-template-rows: 5fr 3fr;
      gap: 2rem;

      .feature-item {
        height: 100% !important;
        gap: 0.5rem !important;
        min-height: auto;

        .feature-item--title {
          gap: 0;
        }
      }

      @media (max-width: $device-xl) {
        width: 480px;
      }

      @media (max-width: $device-md) {
        width: 550px;
      }

      @media (max-width: $device-xs) {
        padding: 0;
        width: 80vw;
        min-width: 80vw;
        min-height: 60vh;
        max-height: 70vh;
        gap: 1rem;
        border-radius: 15px;
      }

      @media (width: 1024px) and (height: 1366px) {
        height: 60vh;
        max-height: 60vh;
        width: 550px;
      }

      @media (min-height: 1200px) {
        max-height: 900px;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .feature-item {
      scroll-snap-align: start;
    }
  }

  &:hover .feature-slider--controls {
    opacity: 1;
  }

  .feature-slider--controls {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem 2rem 2rem;
    transition: opacity 300ms ease-in;
    max-width: $max-view-width;
    margin: 0 auto;

    @media (max-width: $media-query-mobile) {
      display: none;
    }

    .scroll-progress {
      position: relative;
      .scroll-progress--indicator {
        position: absolute;
        width: 100px;
        height: 4px;
        border-radius: 2px;
        background-color: rgba(black, 0.5);
      }
    }

    button {
      color: white;
      display: flex;
      gap: 1rem;
      font-size: 2rem;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 32px;
      }
    }
  }
}
</style>
