<script setup lang="ts">
const { sendScreenView } = useAnalytics();

const { getCdnUrl } = useEnvPaths();

function trackAnalytics(url: string) {
  sendScreenView(
    { action: "UrlClickWeb", category: "JamestownSocial." + url },
    true
  );
}
</script>

<template>
  <div class="social">
    <h3>Follow Us</h3>

    <ul>
      <li>
        <a
          href="https://www.instagram.com/onetimessquarenyc"
          aria-label="Follow us on Instagram"
          target="_blank"
          @click="trackAnalytics('instagram')"
        >
          <nuxt-img
            :src="`${getCdnUrl()}/assets/logos/logo-instagram.svg`"
            width="50"
            height="50"
            alt="Follow us on Instagram"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/onetimessquarenyc/"
          aria-label="Follow us on Facebook"
          target="_blank"
          @click="trackAnalytics('facebook')"
        >
          <nuxt-img
            :src="`${getCdnUrl()}/assets/logos/logo-fb-simple.svg`"
            width="50"
            height="50"
            alt="Follow us on Facebook"
          />
        </a>
      </li>
      <li>
        <a
          href="https://www.tiktok.com/@onetimessquarenyc"
          aria-label="Follow us on TikTok"
          target="_blank"
          @click="trackAnalytics('tiktok')"
        >
          <nuxt-img
            :src="`${getCdnUrl()}/assets/logos/logo-tiktok.svg`"
            width="50"
            height="50"
            alt="Follow us on TikTok"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@import "../assets/scss/_vars";
.social {
  text-align: center;

  h3 {
    padding: 1.5rem;
    font-size: 20px !important;
    font-weight: 800 !important;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 9rem;

    img {
      height: 50px;
    }
  }
}
</style>
